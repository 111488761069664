import React from 'react';
import logo from '../snapshots_font_logo.png';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";
import { SocialMediaIconsReact } from 'social-media-icons-react';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
    marginTop: "40px"
  };
  const contentStyle = {
    background: "rgba(255,255,255,1)",
    width: "20%",
    border: "none"
  };

function sideNav(props) {

    return (
        
            <div>
                    <div className="sideNav">

                        <div className="siteLogoContainer">

                            <div className="topNav">
                                
                                <div className="siteLogo">
                                    <Link to="/" className="App-link">
                                            <img src={logo} className="App-logo" alt="logo" />
                                    </Link>
                                </div>
                                <div className="topNav-item" style={{marginLeft: -6}}>
                                    <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="facebook" iconColor="rgba(56,56,56,1)" backgroundColor="rgba(255,255,255,1)" iconSize="5" roundness="20%" url="https://www.facebook.com/snapshotsbyjeannene/" size="30" />
                                </div>
                                <div className="topNav-item">
                                    <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="instagram" iconColor="rgba(56,56,56,1)" backgroundColor="rgba(255,255,255,1)" iconSize="5" roundness="20%" url="https://www.instagram.com/snapshotsbyjeannene/" size="30" />
                                </div>
                                <div className="topNav-item">
                                    <Link to="/contact" className={ props.activeLink ==='family' ? 'App-link-active' : 'App-link-inactive' }><SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="mail" iconColor="rgba(56,56,56,1)" backgroundColor="rgba(255,255,255,1)" iconSize="5" roundness="20%" url="mailto: snapshotsbyjeannene@gmail.com" size="30" /></Link>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="linkContainer">
                        <div className="sideNav-item">
                            <Link to="/family" className={ props.activeLink ==='family' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Family</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/seniors" className={ props.activeLink ==='seniors' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Senior</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/branding" className={ props.activeLink ==='branding' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Branding</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/newborn" className={ props.activeLink ==='newborn' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Newborn</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/minis" className={ props.activeLink ==='minis' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Themed Session</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/cakeSmash" className={ props.activeLink ==='cakeSmash' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Cake Smash</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/about" className={ props.activeLink ==='about' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>About</Link>
                        </div>
                        <div className="sideNav-item">
                            <Link to="/contact" className={ props.activeLink ==='contact' ? 'App-link-active' : 'App-link-inactive' } style={{ textDecoration: 'none', color: '#383838' }}>Contact</Link>
                        </div>
                    </div>
            </div>
    );
}

export default sideNav;