import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import seniorHeading from '../assets/seniors.png';
import pricingHeading from '../assets/pricing.png';

function seniors() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/seniors/1.jpg",
            alt: 'alt tag info 1',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/seniors/2.jpg",
            alt: 'alt tag info 2',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/seniors/3.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/seniors/4.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/seniors/5.jpg",
            alt: 'alt tag info 3',
        }
    ]

    return (

        <div className="main">
            
            <SideNav activeLink="seniors" />
            
            <div className="sessionContainer">
                <div className="slideShowContainerLeft">
                    <Carousel images={ images }/>
                </div>
                <div className="sessionDescriptionRight">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={seniorHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    Senior sessions are some of my favorite adventures. Capturing a senior's uniqueness is a challenge I love to take on.  These sessions produce memorable images that can be looked back on to remember them before they went out to conquer the world.
                    <br /><br />
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={pricingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    $200 Session Fee<br /><br />
                    $300 print/product purchase required<br /><br />
                    Complimentary digital images with the purchase of Heirloom Album purchase, after minimum purchase
                </div>
            </div>
        </div>
    );
}

export default seniors;