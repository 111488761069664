import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import brandingHeading from '../assets/branding.png';
import pricingHeading from '../assets/pricing.png';

function branding() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/branding/1.jpg",
            alt: 'branding photography',
        },
        {
            url: "http://www.snapshotsbyjeannene.com/images/branding/2.jpg",
            alt: 'branding photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/branding/4.jpg",
            alt: 'branding photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/branding/5.jpg",
            alt: 'branding photography',
        }
    ]

    return (

        <div className="main">
                    
            <SideNav activeLink="branding" />

            <div className="sessionContainer">
                
                <div className="sessionDescriptionLeft">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={brandingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    Brand awareness is a critical component for the success of businesses selling their products or services. I will help you create and maintain your brand with high quality images to use on social media, publications and any other outlet to promote your business.
                    <br /><br />
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={pricingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    $200 session Fee<br /><br />
                    $200-$1000 digital marketing packages with commercial rights
                </div>
                <div className="slideShowContainerRight">
                    <Carousel images={ images }/>
                </div>
            </div>
        </div>
    );
}

export default branding;