import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import familyHeading from '../assets/family.png';
import pricingHeading from '../assets/pricing.png';

function family() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/family/1.jpg",
            alt: 'family photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/family/2.jpg",
            alt: 'family photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/family/4.jpg",
            alt: 'family photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/family/5.jpg",
            alt: 'family photography',
        }
    ]

    return (

        <div className="main">
                    
            <SideNav activeLink="family" />

            <div className="sessionContainer">
                
                <div className="sessionDescriptionLeft">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={familyHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    I love helping families capture their unique personalites, whether it's in the studio or at one of their favorite location.  These memories are prescious and deserve to be preserved in a family photo session.  Big or small, let me help your family create some keepsakes that they will adore forever. 
                    <br /><br />
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={pricingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    <div className="pricingHeading">Session Fee ($150)</div>
                    $150 session fee<br /><br />
                    Print/digital packages start at $190<br /><br />
                    Additional session fee apply for extended families
                </div>
                <div className="slideShowContainerRight">
                    <Carousel images={ images }/>
                </div>
            </div>
        </div>
    );
}

export default family;