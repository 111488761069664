import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import themedHeading from '../assets/minis.png';
import pricingHeading from '../assets/pricing.png';

function minis() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/1.jpg",
            alt: 'alt tag info 1',
        },
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/2.jpg",
            alt: 'alt tag info 2',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/3.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/4.jpg",
           
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/5.jpg",
            alt: 'alt tag info 3',
        }
    ]

    return (
        <div className="main">
                    
            <SideNav activeLink="minis" />

            <div className="sessionContainer">
                
                <div className="sessionDescriptionLeft">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={themedHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    Join me for a quick session to snap some photos of your kids, family or yourself!  I conduct different themed sessions throughout the year.  Each theme is carefully planned and will not be repeated, so don’t miss out.
                    <br /><br />
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={pricingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    $99-$229<br /><br />
                </div>
                <div className="slideShowContainerRight">
                    <Carousel images={ images }/>
                </div>
            </div>
        </div>
    );
}

export default minis;