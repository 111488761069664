import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import homeHeading from '../assets/home.png';
import testimonials from '../assets/testimonials.png';
import janelle from '../assets/testimonials/janelle.png';
import andrea from '../assets/testimonials/andrea.png';
import libby from '../assets/testimonials/libby.png';
import wendy from '../assets/testimonials/wendy.png';

function home() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/family/4.jpg",
            alt: 'family photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/branding/2.jpg",
            alt: 'branding photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/seniors/1.jpg",
            alt: 'alt tag info 1',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/cakeSmash/2.jpg",
            alt: 'alt tag info 2',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/5.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/3.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/family/1.jpg",
            alt: 'family photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/3.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/branding/5.jpg",
            alt: 'branding photography',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/minis/5.jpg",
            alt: 'alt tag info 3',
        }
    ]

    return (
        
        <div className="main">
            
            <SideNav activeLink="home" />
            
            <div className="sessionContainer">
                
                <div className="slideShowContainerLeft">
                    <Carousel images={ images }/>
                </div>

                <div className="sessionDescriptionRight">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={homeHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    We are given many gifts in a lifetime.  Some are as simple as a child’s drawing in bright colored crayons.  Some gifts sparkle as bright as the north star itself.  Family is the most priceless gift of all. 
                    <br /><br />
                    Talents are a gift and mine happens to be of an artistic nature.  Using the skills I've learned to capture and preserve your families memories makes my heart smile.               
                </div>

            </div>

            <div className="bottomDiv">
                    <div className="bottomDivHeading">
                        <div className="logoContainer">
                            <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                            <div className="headingContainer"><img src={testimonials} className="heading" alt="family photo" /></div>
                            <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        </div>
                    </div>
                    <div className="bottomCardContainer">
                        <div className="bottomCard">
                            <div className="testimonialPic">
                                <img src={janelle} alt="family photo" />
                            </div>
                            <div className="testimonialCopy">
                                <i>What an amazing photog!!! She is so personable and fun.. Yes fun! She never gets rattled.  My Grandson just wasnt having it when we were doing his 1yr old pics.. She just kept changing it up and we walked away with some of the most amazing photographs and lifetime memories. -Janelle</i>
                            </div>
                        </div>
                        <div className="bottomCard">
                            <div className="testimonialPic">
                                <img src={andrea} alt="family photo" />
                            </div>
                            <div className="testimonialCopy">
                                <i>We love Jeannene! She is so great and patient with the kids which keeps the session much more relaxed and fun. We always end up with plenty of good, smiley shots which can be tricky with the littles! Highly recommend! - Andrea</i>
                            </div>
                        </div>
                        <div className="bottomCard">
                            <div className="testimonialPic">
                                <img src={libby} alt="family photo" />
                            </div>
                            <div className="testimonialCopy">
                            <i>Jeannene was wonderful to work with.  She is professional, relaxed and great at what she does! - Libby</i>
                            </div>
                        </div>
                        <div className="bottomCard">
                            <div className="testimonialPic">
                                <img src={wendy} alt="family photo" />
                            </div>
                            <div className="testimonialCopy">
                            <i>Jeannene is great to work with!. She makes you feel at ease no matter what kind of shoot you are doing! I have done many different shoots with here and they always turn out amazing! She can get the grumpiest of people to smile - Wendy</i>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default home;