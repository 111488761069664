import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import line from '../assets/line.png';
import headshot from '../assets/about.jpg';
import aboutHeading from '../assets/about.png';

function about() {

    return (

        <div className="main">
            
            <SideNav activeLink="about" />
            
            <div className="sessionContainer"> 
                <div className="slideShowContainerLeftAbout">
                    <img src={headshot} className="about" alt="about" />
                </div>
                <div className="sessionDescriptionRightAbout">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={aboutHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    I've had a passion for photography for as long as I can remember!  I started Snapshots By Jeannene 11 years ago with the goal of doing what I love for a living.  Through those years I have met amazing people and families and have learned more than I could have ever imagined. 
                    <br /><br />
                    Over these 11 years, I have photographed everything from beautiful newborns to unforgettable 100 year old birthday celebrations.  And everything in between.
                    <br /><br />
                    I'm at home photographing in the studio or out in the real world. Both situations get my creative juices flowing.  I'm not afraid to try something new or out of the ordinary to capture that point in time for you and your family.
                    <br /><br />
                    I've been married 41 years and have 4 chilrdrewn who have gifted me with 6 amazing grandchildren.

                    
                </div>
            </div>
        </div>
    );
}

export default about;