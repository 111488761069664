import React from 'react';
import SideNav from './sideNav';
import Form from '../form.js';

function contact() {
    return (

        <div>
            <div>
                <SideNav activeLink="contact" />
            </div>
            <div className="slideShowContainer">
                <Form />
            </div>
        </div>
    );
}

export default contact;