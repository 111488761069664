import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { SocialMediaIconsReact } from 'social-media-icons-react';

function topNav(props) {

    return (
        <div>
            <div className="topNav">
                <div className="topNav-item" style={{marginLeft: -6}}>
                    <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="facebook" iconColor="rgba(56,56,56,1)" backgroundColor="rgba(255,255,255,1)" iconSize="5" roundness="20%" url="https://www.facebook.com/snapshotsbyjeannene/" size="30" />
                 </div>
                 <div className="topNav-item">
                    <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="instagram" iconColor="rgba(56,56,56,1)" backgroundColor="rgba(255,255,255,1)" iconSize="5" roundness="20%" url="https://www.instagram.com/snapshotsbyjeannene/" size="30" />
                </div>
                <div className="topNav-item">
                    <Link to="/contact" className={ props.activeLink ==='family' ? 'App-link-active' : 'App-link-inactive' }><SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="mail" iconColor="rgba(56,56,56,1)" backgroundColor="rgba(255,255,255,1)" iconSize="5" roundness="20%" url="mailto: snapshotsbyjeannene@gmail.com" size="30" /></Link>
                </div>
            </div>
        </div>
    );
}

export default topNav;