import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import newbornHeading from '../assets/newborn.png';
import pricingHeading from '../assets/pricing.png';

function newborn() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/1.jpg",
            alt: 'alt tag info 1',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/2.jpg",
            alt: 'alt tag info 2',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/3.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/4.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/newborn/5.jpg",
            alt: 'alt tag info 3',
        }
    ]

    return (
        <div className="main">
            
            <SideNav activeLink="newborn" />
            
            <div className="sessionContainer">
                <div className="slideShowContainerLeft">
                    <Carousel images={ images }/>
                </div>
                <div className="sessionDescriptionRight">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={newbornHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    Capturing your newly-arrived bundle of joy on camera is a must! To be able to look back on the first few days of your child's life is priceless. And the sessions produce breathtaking images of your baby that you will cherish as they grow older.
                    <br /><br />
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={pricingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    <div className="pricingHeading">In-studio Newborn Session ($400)</div>
                    <div className="pricingDesc">30 fully edited images with printing rights. Family shoots included.</div>

                    <div className="pricingHeading">In-home Newborn Session ($450)</div>
                    <div className="pricingDesc">30 edited images with printing rights</div>

                    <div className="pricingHeading">Wrapped Baby Session ($250)</div>
                    <div className="pricingDesc">In-studio, baby only. 12 digital files with printing rights</div>

                    <div className="pricingHeading">"Fresh 48" / Maternity ($200)</div>
                    Add a "Fresh 48" (at hospital) or maternity portraits to any newborn session
                </div>
            </div>
        </div>
    );
}

export default newborn;