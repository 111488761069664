import React from 'react';
import Carousel from 'nuka-carousel';

class slideShow extends React.Component {
    
  render() {
    const imageArray = this.props.images.map((image, key) =>
        <img src={ image.url } alt={ image.alt }/>
    );

    return (
      <Carousel autoplay='true' autoplayInterval="2000" wrapAround='true' speed="1000" transitionMode="fade" withoutControls='false'>
        { imageArray }
      </Carousel>
    );
  }
}

export default slideShow;