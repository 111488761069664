import React from 'react';
import TopNav from './components/topNav';
import emailjs from 'emailjs-com';
import line from './assets/line.png';
import contactHeading from './assets/contact.png';
import './App.css';
import validator from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import Select from 'react-validation/build/select';
import TextArea from 'react-validation/build/textarea';

const required = (value) => {
  if (!value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return 'Required';
  }
};
 
const email = (value) => {
  if (!validator.isEmail(value)) {
    return `${value} is not a valid email.`
  }
};
 
const lt = (value, props) => {
  // get the maxLength from component's props
  if (!value.toString().trim().length > props.maxLength) {
    // Return jsx
    return <span className="error">The value exceeded {props.maxLength} symbols.</span>
  }
};
 
const password = (value, props, components) => {
  // NOTE: Tricky place. The 'value' argument is always current component's value.
  // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
  // But if we're changing 'confirm' component - the condition will always be true
  // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
  if (value !== components['confirm'][0].value) { // components['password'][0].value !== components['confirm'][0].value
    // 'confirm' - name of input
    // components['confirm'] - array of same-name components because of checkboxes and radios
    return <span className="error">Passwords are not equal.</span>
  }
};

export default class ContactUs extends React.Component {

	constructor(props) {
		super(props);
		this.state = { from_name: "", contact_number: "", reply_to: "", session_type: "", message_html: "" };
	  }
	

  sendEmail = e => {
	//e.preventDefault();

    emailjs.sendForm('snapshotsbyjeannene', 'template_rfvBmkpD', e.target, 'user_v4TV8RyOLJeMhFTspyzvD')
      .then((result) => {
		  console.log(result.text);
		  this.setState({
			from_name: '',
			contact_number: '',
			reply_to: '',
			session_type: '',
			message_html: '',
		  });
      }, (error) => {
          console.log(error.text);
	  });
	  e.target.reset();
  }

  handleFields = e => this.setState({ [e.target.name]: e.target.value }); 

  handleClickOpen = () => {
	this.setOpen(true);
  };

  handleClose = () => {
	this.setOpen(false);
  }; 
  
  render () {

  return (
	<div className="main">
		<div className="sessionContainer">
		
		<div className="contactUs">
			<div className="logoContainer">
				<div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
				<div className="headingContainer"><img src={contactHeading} className="heading" alt="family photo" /></div>
				<div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
			</div>
			If you are interested in booking a session or have any questions, please contact us and we will return your inquery as soon as possible.
            <br /><br />
		</div>
		<div className="contactForm">
			<Form className="contact-form" onSubmit={this.sendEmail}>
				<div className="formElement">
					<label>Name</label>
					<Input type="text" name="from_name" value={this.state.from_name} className="form-control" onChange={this.handleFields} validations={[required]}/>
				</div>
				<div className="formElement">
					<label>Phone Number</label>
					<Input type="text" name="contact_number" value={this.state.from_name} className="form-control" onChange={this.handleFields} validations={[required]} />
				</div>
				<div className="formElement">
					<label>Email</label>
					<Input type="text" name="reply_to" value={this.state.reply_to} className="form-control" onChange={this.handleFields} validations={[required, email]}/>
				</div>
				<div className="formElement">
					<label>Session Type</label>
					<Select name="session_type"  value={this.state.session_type}  onChange={this.handleFields} className="form-control" validations={[required]}>
						<option value='' defaultValue>Choose Session Type</option>
						<option value="Family">Family</option>
						<option value="Senior">Senior</option>
						<option value="Branding">Branding</option>
						<option value="Newbork">Newborn</option>
						<option value="Mini">Themed Session</option>
						<option value="Cake Smash">Cake Smash</option>
					</Select>
				</div>
				<div className="formElement">
					<label>Message</label>
					<TextArea name="message_html" value={this.state.message_html} className="formElementTextArea form-control" style={{ height: 100 }}   onChange={this.handleFields} />
				</div>
				<div className="formElement">
					<Button class="btn btn-primary">Submit</Button>
				</div>
			</Form>
		</div>
		</div>
	</div>
  );
}
}