import React from 'react';
import SideNav from './sideNav';
import TopNav from './topNav';
import Carousel from './image_slider';
import line from '../assets/line.png';
import cakeSmashHeading from '../assets/cakeSmash.png';
import pricingHeading from '../assets/pricing.png';

function CakeSmash() {

    const images = [
        {
            url: "http://www.snapshotsbyjeannene.com/images/cakeSmash/1.jpg",
            alt: 'alt tag info 1',
        },
        {
            url: "http://www.snapshotsbyjeannene.com/images/cakeSmash/2.jpg",
            alt: 'alt tag info 2',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/cakeSmash/3.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/cakeSmash/4.jpg",
            alt: 'alt tag info 3',
        }
        ,
        {
            url: "http://www.snapshotsbyjeannene.com/images/cakeSmash/5.jpg",
            alt: 'alt tag info 3',
        }
    ]

    return (
        <div className="main">
            
            <SideNav activeLink="cakeSmash" />
            
            <div className="sessionContainer">
                <div className="slideShowContainerLeft">
                    <Carousel images={ images }/>
                </div>
                <div className="sessionDescriptionRight">
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={cakeSmashHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    Defintely the messiest sessions we conduct, but also the most fun.  Join me to celebrate your lil' one's first birthday with a good ol' fashioned cake smash.  You provide the cake, and I'll provide the memories.
                    <br /><br />
                    <div className="logoContainer">
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                        <div className="headingContainer"><img src={pricingHeading} className="heading" alt="family photo" /></div>
                        <div className="lineContainer"><img src={line} className="line" alt="family photo" /></div>
                    </div>
                    
                   <div className="pricingHeading">Petite Cake Smash ($200)</div>
                   <div className="pricingDesc">Simple setup with 10 images with printing rights</div>

                   <div className="pricingHeading">Full Cake Smash ($350)</div>
                   <div className="pricingDesc">Themed backdrop with 2 setups before cake smash, family can be included.
                    30 fully edited images with printing rights</div>

                    <div className="pricingDesc">*Parents provide the cake for all cake smash sessions</div>

                    <div className="pricingDesc"><i>Receive a special gift when you book a newborn, milestone and a birthday session during their first year!</i></div>
                </div>
            </div>
        </div>
    );
}

export default CakeSmash;