import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './components/home';
import Family from './components/family';
import Seniors from './components/seniors';
import Branding from './components/branding';
import Newborn from './components/newborn';
import Minis from './components/minis';
import CakeSmash from './components/cakeSmash';
import About from './components/about';
import Contact from './components/contact';

function App() {
  return (
    <div className="App">
      <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/family" component={Family} />
          <Route path="/seniors" component={Seniors} />
          <Route path="/branding" component={Branding} />
          <Route path="/newborn" component={Newborn} />
          <Route path="/minis" component={Minis} />
          <Route path="/cakeSmash" component={CakeSmash} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
      </Switch>
    </div>
  );
}

export default App;
